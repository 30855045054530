<template>
  <div>
    <div className="row card">
      <div className="col-md-12">
        <div className="p-20">
          <h1>Work In Progress</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "AdminsSettingsNewsletterIndex",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Work In Progress",
        route: ""
      }
    ]);
  }
};
</script>
